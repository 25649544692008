export const ru = {
    GMTbranches: '<a href="https://gmtonline.co.il/ru/branches/" style="color:#d4c641;text-decoration: underline;">В одном из филиалов GMT</a>',
    accessibleBranch: 'Доступно для инвалидов',
    accountNumber: 'Номер счёта получателя',
    activityTime: 'Часы работы:',
    actualRate1: 'Курс',
    and: 'и ',
    appDownload: 'Загрузите наше приложение',
    appDownloadBtn: 'Прямая загрузка \n на ваш телефон',
    actualRate2: 'сейчас на сайте',
    addAddress: 'Добавить адрес',
    additionalPhone: 'Дополнителный номер телефона',
    amount: 'Сумма',
    amountIn: 'Сумма в',
    recipientAmount: 'сумма для получения',
    amountReceived: 'Сумма при получении',
    amountTogether: 'Итого',
    anotherSurname: 'Вторая фамилия',
    answerQuestion: 'Может быть, ответ на ваш вопрос здесь',
    approvalTransaction: 'Отлично! Оплата прошла успешно и перевод подтверждён. В ближайшие несколько минут вам поступит смс сообщение с подтверждением перевода.',
    maxCardLoadTransaction: 'Прекрасно! Оплата прошла успешно, и ваша карта myGMT была пополнена деньгами. ',
    approvedPolicies: 'Соглашаюсь с правилами',
    arrivalBranch: 'Наличными в филиале GMT‏',
    arrivalBranchMsg1: 'Сейчас мы предоставим вам номер заказа для оплаты, Заказ действителен в течение 24 часов.',
    arrivalBranchMsg2: ' Оплата выполняется в шекелях по курсу филиала',
    bankName: 'Название банка',
    bank: 'Банк',
    bankAccount: 'Банковский счет',
    bankCode: 'Номер банка',
    cardNumber: 'Hомер карты',
    cashioPageTtile: 'Услуги заказа иностранной валюты, включая доставку  или получение в аэропорту',
    depositCardNumber: 'Номер кредитной карты получателя',
    beneficiary: {
        firstName: 'Имя',
        lastName: 'Фамилия',
        phoneNumber: 'Телефон получателя',
        country: 'Страна назначения‏',
        maskedCardNumber: 'Номер кредитной карты получателя',
        maskedCardNumber_unionPay: "Номер кредитной карты получателя",
        address: 'Адрес',
        city: "Город",
        province: "Область/Округ",
        zipCode: "Индекс",
        bankName: "Наименование Банка",
        cashBankName: 'Точка выдачи',
        walletBankName: 'Имя кошелька',
        swiftCode: 'Банк SWIFT/BIC Код',
        bankCity: 'Город Банка',
        bankAddress: 'Aдрес Банка',
        branch: 'Номер Отделения Банка',
        iban: 'IBAN',
        accountIdentifier: 'Номер счета',
        walletIdentifier: 'Номер кошелька',
        alipayWallet: 'Cчет AliPay',
        abaRoutingNumber: "Банк Раутинг/ABA Номер",
        ifsc: "IFSC",
        relationshipWithCustomer: "Каковы отношения между вами и получателем?",
        identification: "Identification Number",
        upi: "UPI ID or number",
        upi_IND: "UPI ID or number",

        
    },
    relationshipWithCustomer: {
        SELF: 'себе',
        CHILD: 'мой ребенок',
        PARENT: 'мой родитель',
        SPOUSE: 'мой партнер',
        SIBLING: 'мой брат',
        EX_SPOUSE: 'мой бывший супруг'
    },
    beneficiaryRemoveDialogue: 'Вы хотите удалить {beneficiary} из своего списка получателей?',
    beneficiaryRemoveDialogue2: 'Это не повлияет на уже сделанные переводы.',
    billingSuccessful: 'Отлично! Оплата прошла успешно, и перевод подтверждён.',
    billingSuccessfulCardDeposit: ' Отлично! Оплата была успешной, и денежный перевод на карту за границей был подтвержден.',
    billingSuccessfulBankTransfer: 'Отлично! Оплата была успешной, и денежный перевод на банковский счет за границей был подтвержден.',
    billingSuccessfulBankTransferISR: 'Отлично! Оплата была успешной, и денежный перевод на банковский счет был подтвержден.',
    blog: ' Блог ',
    cardUnBlockModalMessage: {
        1: 'После нажатия кнопки «активировать», ваша карта будет вновь доступна для выполнения операций и снятия наличных',
        2: 'пожалуйста, делайте это, только если карта у вас'
    },
    cardBlockModalMessage: {
        1: 'После нажатия кнопки «заблокировать», мы временно заблокируем вашу карту для новых транзакций или снятия наличных.',
        2: 'Баланс вашей карты сохранен!',
        3: 'Если вы ее нашли, вы можете повторно активировать ее здесь, или попросить нас заблокировать ее навсегда и выпустить для вас новую карту'
    },
    blocked: 'заблокирована',
    bntClear: 'Отмена',
    bntContinued: 'Далее‏',
    bntContinue: 'Далее‏',
    bntImgDelete: 'Загрузить снова',
    bntSave: 'Сохранить',
    branchNumber: 'филиал',
    checkStatus: 'Cтатус',
    status: 'Cтатус',
    btnAccept: 'Разрешение',
    btnCheckStatusCheck: 'Готов к выплате',
    btnCheckStatusNoCheck: 'Недоступно для выплаты',
    btnOk: 'Далее',
    btnDone: 'Завершить',
    btnDelete: 'удалить‏',
    btnFinish: 'Завершить',
    btnClose: 'Закрыть',
    btnFinishREgistration: 'далее',
    btnUploadDocuments: 'Загрузите новый докумен',
    btnRegistration: 'Открыть счёт>',
    btnReturn: 'Назад',
    btnSend: 'Отправить',
    btnSignIn: 'Отправьте смс с паролем',
    btnBiometricSignInFaceId: 'Распознавание лица',
    btnBiometricSignInTouchId: 'Отпечатки пальцев',
    btnToMyGmt: 'К myGMT',
    btnTransfer: 'Отправить деньги',
    btnTransferFooter: 'Отправить',
    btnEnter: 'зайти',
    btnSearch: 'Search',
    business: 'Профессия',
    biometricsHeader: 'Биометрическая идентификация',
    biometricSectionApprove: 'Мной приняты правила предоставления услуг',
    biometricSectionSignIn: 'Для завершения регистрации на биометрическую идентификацию, пожалуйста, войдите в аккаунт.',
    biometricToggler: 'Биометрическая идентификация и быстрый вход',
    biometric_modal_text: 'Вы хотите отменить Ваш биометрический вход?',
    biometric_legal_clause_1: 'Биометрическая идентификация (посредством отпечатка пальца или распознавания лица) станет заменой вводу пароля при входе во все аккаунты, связанные с пользователем. ',
    biometric_legal_clause_2: 'Компания не хранит данные распознавания лица, идентификация основывается на биометрической идентификации, настроенной на устройстве пользователя.',
    biometric_legal_clause_3: 'Регистрация в сервисе подразумевает заявление обладателя права входа на сайт или в приложение о том, что он является единственным пользователем устройства с биометрической идентификацией. В случае, если на устройстве настроена биометрическая идентификация для нескольких человек, Вам не следует регистрироваться в сервисе.',
    biometric_legal_clause_4: 'Запрос информации, и/или совершение действий, которые будут осуществлены в приложении или на сайте, будут считаться выполненными обладателем права на вход в сервис, включая действия, совершенные третьей стороной, в нарушение параграфа 3 выше.',
    biometric_legal_clause_5: 'В случае утери и/или кражи мобильного устройства, необходимо немедленно аннулировать регистрацию путём телефонного обращения в службу поддержки.',
    biometric_legal_clause_6: 'Вы можете отключиться от сервиса в любое время, как в приложении, так и связавшись со службой поддержки.',
    biometric_login_failed: 'Извините, идентификация не удалась',
    biometric_public_key_expired:'Извините за неудобства! Мы отключили возможность биометрического входа в Ваш аккаунт, чтобы обеспечить безопасность вашей учетной записи. Пожалуйста, повторно зарегистрируйтесь для биометрического входа. Спасибо!',
    biometric_unregister_modal_title: 'Отключить биометрическую идентификацию',
    canceled: 'Oтменена',
    calculatedBy: 'сумма к оплате в {curr}',
    captionAgreement: 'Соглашаюсь с ',
    captionAgreementLink: 'правилами',
    captionCustomerService1: 'Пожалyйста, обратитесь в службу поддержки, мы с радостью поможем',
    chatRepresentative: 'Чат с нами',
    cashBack: 'myCashBack',
    cashBackReceive: 'Получить Кешбэк',
    cashBackLabel: 'CashBack',
    cashBackBalance1: 'Привет, Вы получили CashBack!',
    cashBackBalance2: 'Ваш баланс составляет:',
    cashBackCredit: 'использованный кешбек',
    cashBackModalMessage: 'Сюрприз!',
    cashBackModalMessage2: 'У вас есть ',
    cashBackModalMessage3: 'от нас',
    cashBackModalMessage4: 'Вы можете использовать их сейчас и платить меньше',
    cashBackModalMessageConfirm: 'Отлично! Хочу использовать сейчас',
    cashBackModalMessageCancel: 'Хочу продолжать накапливать',
    cashBackPageTitlePlus: 'Ура! Вы уже накопили',
    cashBackPageTitleZero: 'Получите деньги обратно',
    cashBackPageMessagePlus: 'Как получить больше CashBack?',
    cashBackPageMessageZero: 'Как накопить',
    cashBackPageMessage2: 'Просто переводите деньги с GMT и получайте деньги обратно',
    cashBackPageMessage3: 'Как только вы достигнете ',
    cashBackPageMessage4: '$/€, вы можете использовать деньги или продолжать накапливать',
    cashBackPageFotter: 'Вы получаете CashBack в валюте перевода, и можете использовать его в той же валюте',
    cashBackPageFotter2: 'CashBack можно использовать в myGMT или в отделениях (скоро)',
    cashBackPageFotter3: 'Если вы не посещали нас более 6 месяцев, ваш CashBack обнулится',
    cashBackPageFotter4: 'Полные правила>',
    cardMargketing: {
        title: 'Пополняемая карта в партнёрстве с ',
        subtitle: 'Мы рады предложить вам удобный способ контроля расходов без необходимости в банковском счёте',
        info1: {
            title: 'Преимущества карты:',
            con1: 'Покупки в Израиле, за границей и он-лайн',
            con2: 'Снятие наличных в банкоматах в Израиле и за границей'
        },
        info2: {
            title: 'Вы всегда можете:',
            con1: 'Проверить баланс карты',
            con2: 'Отслеживать покупки',
            con3: 'Восстановить пин-код',
            con4: 'Заблокировать утерянную или украденную карту'
        },
        footer: {
            1: 'Приобрести новую карту можно в филиалах GMT',
            2: 'Получить подробную информацию или заказать'
        },
        btn: 'Свяжитесь с нами в WhatsApp'
    },
    cardActionsModalTitle: 'Операции по карте',
    cardRoute: 'Пополняемая карта',
    cardHystory: 'Предыдущие транзакции',
    cardBlock: 'Блокировка карты',
    cardBlockBtn: 'Блокировать',
    cardUnBlock: 'Активировать заблокированную карту',
    cardUnBlockBtn: 'Активировать',
    cardBlockConfirm: {
        1: 'Мы заблокировали карту для вас, но вы можете снова активировать её здесь',
        2: 'Мы доступны для обращений по любым вопросам',
        3: 'Связаться с нами'
    },
    walletOrder: {
        deposited: 'Вложение',
        verification: 'Это Ваш код подтверждения',
        getVerification: 'Получить код',
        showTo: 'Покажите этот код в филиале',
        expiry: 'Срок действия кода истечёт через',
        minutes: 'минут',
        returnHere: 'всегда можно вернуться на эту страницу с главной',
        mainPage: 'Главная страница',
        cancel: 'отменить этот заказ',
        hooray: 'Ура',
        close: 'Закрыть',
        depositedToYourWallet: 'Мы вложили',
        depositedToYourWallet1: 'на счёт Вашего кошелька',
        cancelModalTitle: 'Вы хотите отменить этот заказ?',
        cancelModalText: 'Вы всегда можете сделать ещё один',
        goToActiveOrder: 'Код вложения',
        depositCash: 'Вложение наличных на Ваш кошелёк',
        btnOk: 'Подтвердить',
        btnReturn: 'Вернуться',
        amount: 'Сумма',
        summaryOfDetails: 'Детали операции',
        transferType: 'Вид операции'
    },
    wallet: {
        menu: 'Мой Кошелёк',
        balance: 'Мой Кошелёк: ',
        payWith: 'Подтверждение и оплата через "Мой Кошелёк"',
        payment: {
            title: 'Мой Кошелёк',
            body: 'После нажатия кнопки "К оплате" мы выполним запрошенную вами транзакцию и остаток на вашем счёте изменится соответственно.',
            footer: 'После платежа остаток на вашем счёте составит {currency}{amount}.',
            inActive: {
                footer: "Сожалеем, но остаток на вашем счету не достаточен для выполнения этой транзакции. Вы можете изменить сумму транзакции или пополнить ваш кошелёк."
            }
        },
        main: {
            title: 'Привет, {name}!',
            card: {
                title: 'Баланс вашего кошелька',
                transactionsLink: 'Просмотреть все ваши транзакции>'
            },
            depositToWallet: 'Пополнить свой кошелек',
            sendMoney: 'Отправить деньги',
            walletOptions: 'Возможности кошелька',
            depositActions: {
                cash: 'Пополнение\nналичными',
                bank: 'Банковский\nперевод',
                card: 'Платеж\nкартой'
            },
            sendActions: {
                sendHome: 'Перевод денег домой',
                inIsrael: 'Отправить деньги в Израиле',
            },
            walletActions: {
                loadPrePaid: 'Пополняемая карта myGMT',
                send: ' Отправить друзьям myGMT',
                invite: ' Пригласите друзей, получите CashBack'
            },
            doarInstruction: 'Как перевести деньги из "Почтового банка"'
            // doarInstruction: 'Новогоднее мероприятие от GMT'
        },
        invite: {
            title: 'Пригласите друзей и получите CashBack',
            body: 'Вы и ваши друзья можете получить больше от myGMT.\nОтправьте личную ссылку для регистрации своим друзьям. После первой транзакции на myGMT вы оба получите CashBack {amount} шекелей!\n\nВы можете поделиться своей личной регистрационной ссылкой с помощью кнопок ниже',
            shareActions: {
                copy: 'Скопировать',
                copied: 'Скопировано',
                whatsapp: 'Пригласить через WhatsApp',
                wechat: 'Пригласить через WeChat',
                line: 'Пригласить по Line',
                general: 'Поделиться'
            },
            inviteMessages: {
                firstLine: 'Привет, присоединяйтесь к myGMT! по моей личной ссылке ниже начните регистрацию',
                secondLine: 'на myGMT, и мы оба получим CashBack!>'
            }
        },
        deposit: {
            cash: {
                title: 'Внесите наличные на свой кошелек',
                body: 'Вы можете вложить наличные в любом филиале GMT.\nЧерез несколько секунд ваш кошелек myGMT будет обновлен, и вы сразу же сможете им пользоваться.',
                locations: 'Отделения GMT>',
                walletOrderExplanation: {
                    depositAnywhere: 'Можно вложить наличные на кошелёк в любом филиале GMT.',
                    amount: 'На следующей странице укажите сумму вложения',
                    verificationCode: 'получите код подтверждения',
                    teller: 'принесите деньги и покажите код в филиале.',
                    finishTitle: "Всё!",
                    finishText: "Баланс счёта Вашего кошелька будет пополнен немедленно"
                }
            },
            bank: {
                title: 'Перевод из вашего банка или счёта банк хадоар на ваш кошелек',
                body: 'Совершите банковский перевод в GMT и получите его прямо на свой кошелек.\nВаш работодатель также может переводить деньги на ваш кошелек со своего счета.',
                transferDetails: 'Детали счёта:\nGMT tech innovation LTD\nБанк ХаПоалим 12\nФилиал 613\nСчет 545690',
                uploadDetails: 'Загрузите здесь подтверждение перевода, не забудьте добавить ваш паспорт и номер мобильного телефона',
                uploadConfirmationBtn: 'Подтверждение загрузки',
                placeholder: 'Добавить подтверждение банковского перевода',
                upload: {
                    title: 'Загрузите подтверждение перевода',
                    loader: 'Мы загружаем добавленную вами фотографию',
                    footer: 'Не забудьте указать свой паспорт и номер мобильного телефона.',
                    success: 'Чудесно! Наши сотрудники получат его как можно скорее, и баланс вашего кошелька скоро будет обновлен.\nМы также отправим вам подтверждение по SMS и по электронной почте.'
                }
            }
        },
        friends: {
            title: 'Отправить друзьям myGMT',
            addFriend: 'Добавить нового друга',
            newFriend: 'Перевод на кошелёк myGMT',
            addContactByPhone: 'Перевод по номеру, которого нет в списке контактов',
            searchByNameOrPhone: 'Поиск по имени и номеру',
            header: 'нашей простой в использовании функцией денежных переводов вы можете безопасно отправлять средства любому из своих друзей в myGMT.',
            contacts: 'Выбор из списка контактов',
            mobilePhone: 'номер телефона',
            bulletsOne: 'Выберите номер из списка контактов.',
            bulletsTwo: 'Введите сумму перевода.',
            bulletsThree: 'Подтвердить.',
            outro: 'Готово! Кошелек Вашего друга будет пополнен незамедлительно. \n Хотите с лёгкостью перевести деньги сейчас?',
            inviteBnt: 'Пригласите друзей в myGMT',
            list: {
                title: 'Список друзей'
            },
            addModal: {
                title: 'Вы хотите добавить',
                footer: 'в список ваших друзей?',
                saveBtn: 'Сохранить',
                closeBtn: 'Отменить'
            },
            friendCard: {
                title: 'Кошелек',
                delete: 'Удалить',
                deleteDialogFirstLine: 'Вы хотите удалить {beneficiary} из списка друзей вашего кошелька?',
                deleteDialogSecondLine: 'Это не повлияет на существующие переводы',
                send: 'Отправить на кошелек'
            }
        },
        transfer: {
            amount: {
                title: 'Отправить деньги на кошелек myGMT'
            },
            send: {
                title: 'Отправьте {amount} ILS на кошелек myGMT'
            },
            summary: {
                title: 'Отправьте {amount} ILS на кошелек myGMT'
            },
            pay: {
                title: 'Отправьте {amount} ILS на кошелек myGMT'
            },
        },
        load: {
            amount: {
                title: 'Пополните свой кошелек и оплатите картой'
            },
            send: {
                title: 'Пополните свой кошелек'
            },
            summary: {
                title: 'Пополните свой кошелек'
            },
            pay: {
                title: 'Пополните свой кошелек'
            },
        },
        card: {
            amount: {
                title: 'Пополнение карты и оплата из кошелька'
            },
            send: {
                title: 'Пополнение карты и оплата из кошелька'
            },
            summary: {
                title: 'Пополнение карты myGMT'
            },
            pay: {
                title: 'Пополнение карты myGMT'
            }
        }
    },
    pay: 'К оплате',
    loadCard: 'Пополнение и оплата из кошелька',
    cardUnBlockConfirm: 'Ваша карта сейчас активна',
    cardMerchantAddress: 'Адрес',
    cardMerchantPhone: 'Телефон',
    cardPresented: 'Представлена карта',
    changeAmount: 'Хотите изменить сумму отправки?',
    changePayoutAmount: 'Хотите поменять на сумму для получателя?',
    changeAmountLabel: 'Новая сумма в {amount}',
    changeAmounBtn: 'Рассчитайте',
    city: 'Город',
    commission: 'Комиссия',
    confirmMarketingContent: ' Даю согласие получать рекламу',
    confirmCreditNumber: 'Очень важно! Невозможно отменить перевод сделанный на банковскую карту',
    confirmBankAccount: 'Очень важно! Пожалуйста, проверьте данные счета, невозможно отменить перевод на банковский счет за границу.',
    confirmBankAccount2: 'Счет получателя будет пополнен в течение 2-4 рабочих дней.',
    contactBtnCaption: 'Службa поддержки >',
    contactMenuCaption2: 'Messenger',
    contactMenuCaption3: 'Звонок',
    contactMenuCaption4: 'Whatsapp',
    country: ' Страна',
    countryBirth: 'Страна рождения',
    countryDestination: 'Страна назначения',
    countryFrom: 'Страна происхождения',
    correspondentReference: 'Код перевода',
    qrCodeDownload: "Download QR",
    scanExplanation: "Scan this QR code find us on WeChat",
    cookies: 'На этом сайте используются файлы Cookies. Продолжение пользования сайтом служит доказательством Вашего согласия на их использование. С дополнительной информацией Вы можете ознакомиться в <a style="text-decoration:underline;display: inline;" href="https://gmtonline.co.il/en/privacy/" target="_blank">Политике в отношении защиты конфиденциальности частной информации.</a>',
    clone: 'Отправить снова',
    payingReference: 'Номер заказа',
    creditCard: 'Кредитной картой',
    crediting: 'вложение‏‏',
    creditCardOwner: 'Имя на карте',
    creditCardSupport: 'Мы поддерживаем',
    creditCardCommissionMsg: 'Ваша кредитная компания может взымать комиссию за конвертацию',
    creditCardSecurityMsg: 'Мы не храним данные вашей карты, они передаются только компании-эмитенту  для просмотра и проверки',
    cardDepositAccepted: 'Услуга предоставляется только для карт Visa/MasterCard',
    Contact: 'Contact',
    currency: 'Валюта',
    currencyDestination: 'валюта получателя',
    currencyILS: 'ILS',
    currencyUSD: 'USD',
    currencyEUR: 'EUR',
    currencyCardMsg: 'Окончательная сумма к оплате зависит от типа и валюты карты',
    currencyBankMsg: 'Окончательная полученная сумма будет зависеть от типа и валюты счета‏',
    dateBirth: 'Дата рождения',
    daysWork: ' вс. - чт.',
    daysWork2: 'пт 08:30-14:00, вс. - чт. 08:30-19:00',
    daysWork3: 'пт.',
    destination: 'Страна назначения‏',
    discount: 'Cкидка',
    docsTitle: 'Сфотографируйте удостоверение личности',
    docsTitleUploaded: 'Спасибо, мы почти закончили!',
    documentsUploading1: 'Мы сохраняем добавленное Вами фото',
    documentsUploading2: 'это займет ещё несколько секунд',
    documentsUploading3: 'После этого нажмите Далее',
    editUserProfile: 'Мои данные',
    email: 'Электронная почта',
    errorCode: 'Неверный код, повторите попытку',
    expired: 'Cрок истек',
    estimated: 'Примерно',
    calculatedAbroad: 'будет рассчитано за границей',
    extraDetails: 'Дополнительные данные‏',
    exchangeRate: 'Курс валюты',
    byExchangeRate: 'Курс валюты',
    fax: 'Факс',
    for: 'Для',
    finished: 'Завершить',
    foreignPassport: ' Паспорт другой страны',
    to: 'в ',
    freeSearch: 'Поиск ...',
    friday: 'пт.',
    fullName: 'Полное имя',
    fun: 'Здорово! Мы обещаем сообщать только то, что интересно',
    foreignCurrencyHandlingFee: 'Комиссия за обработку иностранной валюты',
    gender: 'Пол',
    new: 'Новое',
    newCustomer_section: 'Правильный выбор для ваших денег',
    quickSend: 'Быстрая отправка',
    quickActions: 'Быстрые операции',
    quickSendActionsParagraph: 'Последние выбранные Вами получатели здесь, всё готово для быстрой отправки',
    quickActionsAdd: 'Добавить',
    payAnywhere: 'Пополняемая карта ',
    carousel_slide_one_text: 'Наша пополняемая карта поможет вам ещё лучше контролировать Ваши деньги',
    carousel_slide_one_button: 'Узнать больше',
    seeAll: 'Все',
    contact_us_paragraph: 'Мы верим в пользу общения, поэтому не стесняйтесь обращаться к нам любым, указанным способом. Наши часы работы: пн-чт: 08:30-17:00; пт: 08:30-14:00',
    changeLang: 'Change Language',
    cardBalance: 'Остаток на карте',
    getCashback: 'и получить',
    cashBack_info: 'Кешбэк',
    home: 'Главная',
    menu: 'Меню',
    activity: 'Действия',
    cards: 'Карты',
    greeting_morning: 'Доброе утро',
    greeting_afternoon: 'Добрый день',
    greeting_evening: 'Добрый вечер',
    greeting_night: 'Доброй ночи',
    paidBy: 'Оплачено посредством',
    incoming: 'Вы получили',
    outgoing: 'Вы заплатили',
    CONVERSION: 'Конвертация',
    PAYMENT: 'Оплата',
    CASH_BACK: 'Кешбэк',
    cardActions: 'Операции по карте',
    actions: 'Операции',
    activateCard: 'Активация карты',
    activateCardAction: 'Активировать карту',
    enterLastDigits: 'Введите последние 4 цифры для активации',
    searchTransactions: 'Поиск транзакций',
    filterTransactions: 'Фильтр транзакций',
    filterTransactionsButton: 'Фильтр',
    openWallet: 'Бесплатно завести кошелёк',
    pleaseSelect: 'Выбрать одно или более',
    date: 'Дата',
    from_date: 'С',
    to_date: 'До',
    choose_date: 'Выбор даты',
    showResults: 'Показать результаты',
    clearFilter: 'Сбросить фильтры',
    today: 'Сегодня',
    call_us: 'Позвонить нам',
    contact_us_or: 'или',
    lastTransactions:'Последние операции по карте',
    transactionClasses: {
        BANK_TRANSFER: 'Перевод на банковский счёт',
        CASH_TRANSFER: 'Перевод для получения наличных',
        CARD_DEPOSIT: 'Перевод на карту',
        PAYMENT: 'Входящий перевод',
        CONVERSION: 'Конвертация валют',
        CASH_BACK: 'Кешбэк',
        WALLET_DEPOSIT: 'Вложение на Ваш кошелёк',
        WALLET_TRANSFER: 'Перевод на электронный кошелёк',
        WALLET_TO_WALLET: 'Перевод на кошелёк MyGMT',
        WALLET_FROM_WALLET: 'Входящий перевод на кошелёк MyGMT'
    },
    paymentTypes: {
        CASH: 'Наличные',
        CHECK: 'Чек',
        BANK_TRANSFER: 'Банковский перевод',
        CREDIT_CARD: 'Карта',
        CREDITTING: 'Карта',
        OUTER_CONVERSION: 'Наличные',
        CASHBACK: 'Кешбэк',
        CUSTOMER_DEPOSIT: 'Баланс третьей стороы',
        WALLET: 'Кошелёк',
        CHANGE: 'Сдача',
        AIRPORT: 'Аэропорт',
    },
    nothing_found: 'Транзакции не найдены',
    nothing_found_text: 'Когда Вы выполните новые транзакции, они появятся здесь',
    creditCardReferenceNumber: 'Номер платежа от кредитной компании',
    checkNumber: 'Номер чека',
    referenceNumber: 'Подтверждение',
    flightDate: 'Дата полета',
    deposit_to_wallet: 'Пополнение кошелька',
    contactMenuCaption1: 'Телеграм',
    CARD: 'Карта',
    CASH: 'Наличные',
    AIRPORT: 'Аэропорт',
    CHECK: 'Чек',
    CHANGE: 'Сдача',
    CREDIT_CARD: 'Карта',
    CREDITTING: 'Карта',
    OUTER_CONVERSION: 'Наличные',
    CASHBACK: 'Кешбэк',
    CUSTOMER_DEPOSIT: 'Баланс третьей стороы',
    WALLET: 'Кошелёк',
    quick_guide: 'Краткое руководство для Вас',
    invite_friends: 'Пригласить друзей в MyGMT',
    cash_back_desc: 'Кешбэк',
    send_money_home: 'Перевод денег домой',
    send_money_worldwide: 'Перевод денег за границу',
    here: ' Здесь',
    hours: 'Обратите внимание! Вам необходимо в ближайшие 48 часов обратиться в отделение GMT для завершения платежа',
    houseNumber: 'Номер дома',
    welcome: 'Здравствуйте',
    interest: 'включая проценты {inter} шек',
    interested: 'Может быть, вас заинтересует наш блог?',
    israel: 'Израиль',
    israeliID: ' И‏зраильское гражданство',
    israeliResident: 'Гражданин Израиля',
    nonIsraeliResident: 'Иностранный гражданин',
    installments: 'Платежи',
    inProcess: 'В процессе',
    imprtant: 'Important',
    itemNavMain: 'My Wallet',
    itemNav0: 'myCashBack',
    itemNav1: 'Перевод денег',
    itemNav10: 'Отделения',
    itemNav2: 'Моя история',
    itemNav3: 'Курс и комиссионные',
    itemNav4: 'Мои получатели',
    itemNav5: 'Отделения GMT',
    itemNav6: 'Вопрос-ответ',
    itemNav7: 'Контакты',
    itemNav8: 'Правила',
    itemNav9: 'Выход',
    itemNav11: 'Узнать больше‏',
    itemNav12: 'My Card',
    itemNavUserProfile: 'Настройки',
    itemNav13: 'Заказ иностранной валюты',
    labelEmail: 'Электронная почта',
    labelMsg: 'Как вам помочь?',
    labelName: 'Имя по-английски',
    labelPhone: 'Номер мобильного телефона',
    labelSurname: 'Фамилия по-английски',
    lblDone: 'Перевод отправляется как подарок или поддержка родственнику первой степени и не является доходом получателя',
    lblDoneWechat: 'Note that for the first transaction, your recipient needs to approve the WeChat transaction. Tell them to click on the SMS link.',
    lblDoneWechat1: 'Please add 86 before your Chinese mobile number linked with WeChat, or 972 before your Israeli mobile number.',
    leaveMsg: 'Как вам помочь?',
    linkToLoginPage: 'Уже зарегистрировались?Главная страница>',
    listBranches: 'Найти отделение GMT',
    locatingBranches: 'Поиск филиалов',
    locationBtn: 'Открыто сейчас',
    locationBtnClose: 'Закрыт',
    loginLabel1: 'Номер удостоверения личности / паспорта',
    loginLabel2: 'Номер мобильного телефона',
    logInConsent: 'При входе в аккаунт, мной приняты',
    mailingAddress: 'Электронная почта',
    mailingRecipient: 'Рауль Валленберг 18C Тель-Авив, 6971915',
    marked: 'Обозначенный',
    maybe: 'Может быть, вас заинтересует наш блог?',
    messageCode: 'На ваш номер выслано сообщение с кодом. Введите код из SMS',
    messageSend: 'В ближайшие несколько минут вам поступит SMS-сообщение  и мейл с подтверждением перевода‏‎.',
    messageNotSent: 'Возникли проблемы с получением пароля? Пожалуйста, проверьте папку Входящие СМС и папку Спам. Мы будем рады помочь с любыми вопросами.',
    messageRegistration1: 'Мы рады, что вы вернулись!',
    messageRegistration2: 'Загрузите удостоверяющий личность документ и закончите регистрацию',
    messageUploadDocuments: 'Ваш документ, удостоверяющий личность, больше не действителен, пожалуйста, загрузите новый',
    middleName: 'Отчество',
    mobilePhone: 'Номер мобильного телефона',
    mobilePhoneNumber: 'Израильский мобильный номер',
    monday: 'пн.',
    moneyApproved: 'Перевод одобрен!',
    MoneyGram: 'MoneyGram',
    moreOptions: 'Дополнительные возможности',
    msgError: 'Используйте только цифры',
    maxAccessionCommission: 'Комиссия за выдачу карты',
    myCard: 'Моя карта',
    myDateBirthday: ' (День-Mесяц-Год) ',
    myId: 'Номер удостоверения личности',
    nameBeneficiary: 'Имя получателя',
    newBeneficiary: 'Новый получатель',
    updateBeneficiary: 'Данные получателя',
    nextLevel: 'Далее',
    no: 'Нет',
    noBeneficiaries: 'В системе не были определены получатели',
    noRecentActions: 'Добро пожаловать в ваш личный кабинет myGMT',
    noRecentActions1: 'Здесь вы можете увидеть свою историю переводов в GMT',
    noRecentActions2: 'Давайте начнем!',
    noCardRecentActions: 'Сделок не найдено',
    notAvailable: 'Не доступно',
    notRequired: 'Не обязательно',
    numberPassport: 'Номер паспорта',
    numberOfTransaction: 'Номер квитанции',
    openNow: 'Открыто сейчас',
    payUsing: 'Я оплачу',
    toPay: 'Платёж',
    payRate: 'по курсу {rate}',
    paymentCredit: 'Оплата по кредитной карте',
    photoID: 'Паспорт, водительские права, теудат зеут‏',
    photoRequired: 'Загрузить документ',
    pinCode: 'Просмотр пин-кода',
    pinCodeModalMessage: 'Хотите посмотреть пин-код карты, оканчивающейся на {digits}',
    pinCodeModalMessage2: 'Мы отправили вам текстовое сообщение на  мобильный телефон для просмотра пин-кода',
    placeholderDigit: ' 9 цифр ',
    questionsAnswers: 'Вопрос-ответ',
    questionsAnswersSubtitle: 'Мы собрали для вас часто задаваемые вопросы о денежных переводах и других финансовых услугах.Не находите ответ на ваш вопрос? Пожалуйста, свяжитесь с нами одним из способов, указанных на странице "Обратная связь".',
    rate: 'Курс',
    reasonTransfer: 'Причина перевода',
    recentActions: 'История переводов',
    recentActionsTab1: 'Новый получатель',
    recentActionsTab2: 'Обмен валюты в аэропорту имени Бен-Гуриона',
    recentActionsTab3: 'Зарядка карты',
    recentActionsTab4: 'Получение денег',
    recentActionsTab5: 'Покупка биткоина',
    recommended: 'Рекомендуем',
    recentActionsBeneficiaries: 'Мои получатели',
    recentActionsTransfer: 'Перевод денег',
    r_step_address: 'Aдрес',
    r_step_doc: 'Док',
    r_step_name: 'Имя',
    registerSystem: 'Зарегистрируйтесь в myGMT',
    registerSystemAddDocuments: 'Загрузите‏ документ‏',
    registerText1: 'Через мгновение вы сможете отправить деньги',
    registerText2: 'Приготовьте ваше удостоверением личности, мы попросим вас сфотографировать его, давайте начнем!',
    registerFooter: 'GMT имеет расширенную лицензию провайдера',
    registerFooter2: 'финансовых услуг № 57479',
    registerFooter3: 'Наш сайт зашифрован и защищен, и мы никогда не передадим ваши данные кому-либо.',
    registrationSuccess: 'Регистрация прошла успешно!',
    registrationSuccess1: 'Спасибо! Мы получили все данные',
    registrationSuccess2: 'С myGMT вы можете отправлять деньги за границу легко и быстро.',
    requestSuccessfully: 'Ваш запрос успешно отправлен',
    requestSuccessfully1: 'Порядковый номер: ',
    requestSuccessfully2: 'Вам необходимо в ближайшие 24 часов оплатить перевод ',
    requestSuccessfully3: '<a href="https://gmtonline.co.il/ru/branches/" style="color:#d4c641;text-decoration: underline;">в одном из филиалов GMT</a>',
    requestSuccessfully4: 'после этого он будет отправлен получателю.',
    russianSpeaker: 'Говорим по-русски',
    riskyLimitMsg: 'Для перевода более крупных сумм, Вы можете связаться с нами.',
    saturday: 'сб.',
    searchResult: 'Найдено X филиалов в XXX',
    selectBeneficiary: 'Мои получатели',
    selectCity: 'Поиск по городу',
    selectCountry: 'Гражданство',
    selectProvince: 'Выберите штат',
    sendMarketingContentLbl: 'Согласен получать новости',
    sentOn: 'Дата',
    signedUp: 'Вы подписались!',
    soonPossible: 'Мы ответим в часы работы',
    staging: {
        payWith: 'Оплата наличными в отделениях GMT',
        body: 'После того, как вы нажмете кнопку «оформить заказ», мы подготовим для вас перевод. Перевод будет отправлен после оплаты в одном из офисов GMT.',
        order: 'оформить заказ'
    },
    streetName: 'Улица',
    streetName_HouseNumber: 'Улица и номер дома',
    subscribeDesc: 'Последние новости из мира финтека здесь - в нашем блоге и рассылке',
    subscribeNewsletter: 'Давайте дружить! Подписывайтесь на нашу рассылку!',
    subtitleCustomerService: 'Пожалуйста, обратитесь в службу поддержки, мы с радостью поможем',
    summaryTransfer: 'Детали операции',
    sunday: 'вс.',
    surtitleFormLang: 'Выберите язык',
    smartBannerTitle: 'Обновите приложение myGMT',
    smartBannerText: 'Легко. Быстро. Надежно.',
    smartBannerBtnText: 'Скачать',
    system: 'Система',
    correspondentSystem: 'Пункты выплат',
    termsUse: 'Правила',
    termsTitle: 'Прочитайте и примите условия',
    tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
    surveyModal: {
        active: true,
        title: 'Поделитесь своими мыслями: мы подготовили для Вас небольшой анонимный опрос',
        body: 'Привет {name}, мы ждём Вашего ответа! Помогите нам улучшить наши финансовые услуги с помощью небольшого анонимного опроса. Ваше мнение очень важно. Можно начать сейчас',
        confirm: 'Начать опрос сейчас',
        cancel: 'Позже',
        link: 'https://forms.gle/t6xayuFwH8CKt9C37'
    },
    termsUpdate: {
        title: 'Условия и политика конфиденциальности',
        body: {
            first: 'Здравствуйте, к вашему сведению, наши условия и политика конфиденциальности обновлены,',
            second: 'ознакомиться с ними можно здесь>'
        }
    },
    thanks: 'Спасибо, что обратились к нам!',
    thursday: 'чт.',
    titleFormLang: 'Добро пожаловать!',
    titleLoginPage: 'Войдите в myGMT',
    toBankAccount: 'на счёт',
    toWalletAccount: 'на кошелёк',
    pickupPoint: 'Точка выдачи',
    toCreditCard: 'на карту',
    totalNIS: 'Итого в шекелях',
    totalTransfer: 'всего для оплаты',
    total: ' Сумма ',
    t_step_amount: 'Сумма',
    t_step_transfer: 'Перевод',
    t_step_summary: 'Итого',
    t_step_pay: 'Оплата',
    transferAmount: 'Сумма перевода',
    transactionAmount: 'Сумма',
    transactionCharge: 'Сортировать по',
    filterPaymentType: 'Вид платежа',
    transactionType: '  Тип транзакции',
    transferFundsTo: 'Перевод',
    transferOptions: 'Параметры перевода',
    transferType: 'Вид перевода',
    transferTypePick: 'Пожалуйста выберите Вид перевода',
    tuesday: 'вт.',
    waitingPayment: ' - Ожидается оплата',
    wednesday: 'ср.',
    yourCardBalance: 'Баланс карты',
    zipCode: 'Индекс',
    loginAgreementRequired: 'Прочитайте и примите условия',
    customerCommission: 'Ваша комиссия ',
    customerCommissionMaxTopup: 'Комиссия за пополнение',
    customerEmailNotification: {
        title: 'Обновление электронной почты',
        body: 'Кажется, у нас нет вашей электронной почты\nВведите его ниже, чтобы мы могли отправить вам копию вашей квитанции.\nМы никогда не будем рассылать спам.',
        cancel: 'Назад'
    },
    sumAmount: 'Сумма',
    terms: 'Правила',
    error: 'Ошибка',
    generalErrorMsg: 'К сожалению наш сайт закрыт на несколько минут, просьба подождать и попробовать зайти позже',
    connectionError: 'connectionError',
    locationLink: 'ru/branches/',
    faqLink: 'ru/faq/',
    contactUsLink: 'ru/contact-us/',
    paymentFrom: 'Оплата от',
    protocolLink: 'ru/protocol/',
    privacyLink: 'en/privacy',
    privacyPolicy: 'Политика конфиденциальности',
    profileDelete: "Удаление Вашего профиля",
    profileDeleteModalTitle: "Вы хотите отменить Ваш MyGMT профиль?",
    profileDeleteModalText: "При нажатии кнопки Подтвердить, мы получим сообщение и вернемся к Вам в ближайшее время",
    aboutLink: 'ru/my-gmt/',
    paymentType: 'Способ оплаты',
    paymentTypeCredit: 'Оплата банковской картой',
    paymentTypeByCredit: 'оплачено картой',
    paymentTypeCash: 'Наличные',
    uploadDocumentMsg1: 'Мы обязаны подтвердить вашу личность, поэтому мы просим присоеденить ваш удостоверяющий личность документ .',
    uploadDocumentMsg2: 'Ваша конфиденциальность очень важна для нас, наш сайт зашифрован и защищен, и мы никогда не передадим вашу информацию кому-либо.',
    version: 'v {num}',
    yes: 'Да',
    zipCodePlaceholder: '7 цифр',
    zipCodeRequiredFields: ' Пожалуйста, заполните ваш адрес, чтобы мы могли найти соответствующий почтовый индекс',
    zipCodeNotFound: 'Мы не нашли почтовый индекс для введенного вами адреса, вы можете проверить адрес еще раз или вручную ввести 7-значный почтовый индекс',
    CORRESPONDENT_BLOCKED: 'К сожалению, выбранный вами сервис временно неактивен, мы рекомендуем выбрать другую опцию или попробуйте позже, мы будем рады помочь с любыми вопросами.',
    CARD_DEPOSIT: 'Перевод на карту банка',
    CARD_DEPOSIT_TO: 'Перевод на карту банка в {dest}',
    CARD_DEPOSIT_MAX: 'Пополнение карты',
    CASH_TRANSFER: 'Наличный перевод',
    CASH_TRANSFER_INlAND: 'Наличный перевод',
    CASH_TRANSFER_ISR: 'Перевод наличных‏‏',
    CASH_TRANSFER_TO: 'Наличный перевод в {dest}',
    BANK_TRANSFER: 'На банковский счёт',
    BANK_TRANSFER_ISR: 'На банковский счёт',
    UPI_BANK_TRANSFER: 'Transfer via UPI ID',
    BANK_TRANSFER_TO: 'Перевод на счет в {dest}',
    BANK_TRANSFER_PAYMENT: 'Банковский перевод',
    WALLET_TRANSFER: 'Перевод на кошелёк за границей',
    WALLET_TRANSFER_TO: 'Перевод на кошелёк в {dest}',
    WALLET_TO_WALLET: 'Отправить на кошелек myGMT',
    WALLET_FROM_WALLET: 'Получать из кошелька myGMT',
    WALLET_DEPOSIT: 'Пополнить кошелек myGMT',
    NEW_CUSTOMER: 'Здравствуйте, мы подтвердим вашу регистрацию в течении 24 часов. В это время, возможность оплаты кредитной картой недоступна, мы сообщим вам, как только закончим.',
    CUSTOMER_NOT_AUTHORIZED: 'Обратите внимание, что в вашем профиле закрыта опция оплаты кредитной картой, мы будем рады помочь с любыми вопросами.',
    CUSTOMER_NOT_SIGNED: 'Извините, вы не завершили процесс регистрации, поэтому в вашем профиле по-прежнему закрыта опция оплаты по кредитой карте. Мы будем рады помочь с любыми вопросами.',
    CREDIT_LIMIT: 'Вы можете отправить в этом месяце до ',
    CREDIT_LIMIT_MONTHLY: 'Вы можете отправить в этом месяце до {amount}',
    CREDIT_LIMIT_CREDIT_CARD: 'Сумма перевода превышает наш месячный максимум для оплаты картой. Вы можете использовать ещё до {amount} шекелей',
    CREDIT_LIMIT_WALLET: 'Сумма перевода превышает наш месячный максимум для оплаты с кошелька. Вы можете использовать ещё до {amount} шекелей',
    CREDIT_LIMIT_UNREGISTERED: 'Вы можете отправить в этом месяце до ',
    CREDIT_LIMIT_RISKY: 'Вы можете отправить в {country} до',
    TRANSACTION_LIMIT: 'К сожалению, этот перевод ограничен {currency}{amount} на транзакцию. Вы можете ввести меньшую сумму и отправить ее. Будем рады ответить на любые вопросы.',
    CREDIT_CARD_ILS_AMOUNT: 'Расчет суммы в шекелях',
    CREDIT_NOT_ACTIVE: 'Извините,  опция оплаты кредитной картой закрыта. Мы вернемся в рабочее время с 6 утра до 24 вечера.',
    CREDIT_BLOCKED: 'К сожалению наш сайт закрыт на несколько минут, просьба подождать и попробовать зайти позже',
    API_EXCEPTION: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    GRAILS_VALIDATION_ERROR: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    CAPTCHA_NOT_VERIFIED: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    CUSTOMER_SESSION_NOT_FOUND: 'Идентификационные данные неверны или вы еще не зарегистрированы в myGMT, вы можете сделать это <a href="https://mygmt.gmtonline.co.il/#/registration" style="color:#d4c641;text-decoration: underline;">здесь ></a>',
    CUSTOMER_BLOCKED: 'К сожалению, профиль закрыт. Пожалуйста, обратитесь в <a href="https://gmtonline.co.il/ru/contact-us/" style="color:#d4c641;text-decoration: underline;">службу поддержки > </a>',
    SMS_WRONG: 'Неверный SMS код',
    SMS_EXPIRED: 'К сожалению, срок действия SMS кода истек. Пожалуйста, получите новый.',
    REGISTRATION_TOKEN_NOT_FOUND: 'Неверный SMS код',
    REGISTRATION_TOKEN_EXPIRED: 'К сожалению, срок действия SMS кода истек. Пожалуйста, получите новый.',
    SESSION_EXPIRED: 'Время ожидания истекло, мы вышли из вашего личного кабинета. Пожалуйста, войдите снова',
    CUSTOMER_MOBILE_NOT_MATCH: 'Для регистрации в myGMT обратитесь, пожалуйста, в <a href="https://gmtonline.co.il/ru/contact-us/" style="color:#d4c641;text-decoration: underline;">службу поддержки > </a>',
    CUSTOMER_ALREADY_REGISTERED: 'Вы уже зарегистрированы и можете войти в свой <a href="https://mygmt.gmtonline.co.il" style="color:#d4c641;text-decoration: underline;">личный кабинет > </a>',
    WALLET_FRIEND_NOT_UNIQUE: 'Извините, мы не можем добавить этот номер в ваш список друзей. Мы будем рады помочь, пожалуйста, нажмите "связаться с нами", чтобы задать любой вопрос.',
    CUSTOMER_DENIED: 'Мы рады, что вы вернулись! Мы можем помочь вам зарегистрироваться и отправить деньги за границу',
    BENEFICIARY_NOT_FOUND: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    TRANSACTION_NOT_FOUND_FOR_CUSTOMER: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    DOCUMENT_NOT_FOUND: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    DOCUMENT_NOT_FOUND_FOR_CUSTOMER: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    MONEYGRAM_VALIDATION_ERROR: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    CREDIT_CARD_SIGN_ERROR: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    CREDIT_CARD_VERIFY_ERROR: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    CREDIT_CARD_COMMIT_ERROR: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    CUSTOMER_MULTIPLE: 'Для регистрации в myGMT обратитесь, пожалуйста, в <a href="https://gmtonline.co.il/ru/contact-us/" style="color:#d4c641;text-decoration: underline;">службу поддержки > </a>',
    MONEYGRAM_EXCEPTION: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    MONEYGRAM_301: 'Максимальная сумма составляет',
    MONEYGRAM_304: 'К сожалению, наша служба денежных переводов в настоящее время закрыта. Мы вернемся в рабочие дни с 8:00 до 21:00 и по пятницам с 8:00 до 15:00.',
    MONEYGRAM_323: 'К сожалению, имя получателя, которое вы ввели, слишком длинное. Вы можете попробовать еще раз с более коротким именем. Мы будем рады помочь.',
    MONEYGRAM_4055: 'Мы сожалеем, но MoneyGram отклонил этот перевод, чтобы защитить вас, и перевод был отменен. Мы будем рады помочь.',
    MONEYGRAM_7003: 'Извините, но вы достигли месячного лимита в системе MoneyGram.  Сделка не была выполнена.  Мы будем рады помочь с любыми вопросами.',
    FILE_NOT_ALLOWED: 'есть проблема с файлом',
    INVALID_SESSION_INFO: 'Что-то пошло не так. Пожалуйста, попробуйте снова',
    KYC_EXCEPTION: 'Приносим извинения за неудобство. Для завершения транзакции нам требуются дополнительные данные. Пожалуйста, свяжитесь с отделом обслуживания клиентов для получения помощи. Спасибо.',
    WALLET_FRIEND_ALREADY_ADDED: 'Привет! Этот номер уже есть в вашем списке друзей.',
    WALLET_FRIEND_BLOCKED: 'Извините, мы не можем добавить этот номер в ваш список друзей. Пожалуйста, свяжитесь с нами для получения дополнительной информации',
    WALLET_FRIEND_NOT_REGISTERED: 'К сожалению, номер мобильного телефона {mobileNumber} еще не зарегистрирован в myGMT. Используйте свою личную ссылку для регистрации, чтобы пригласить друзей и получить CashBack!',
    marketingMessage: 'С помощью myGMT вы можете отправлять деньги родственникам за границу и получать их в сотнях тысяч пунктов оплаты по всему миру с помощью секретного кода, а также вкладывать деньги непосредственно на кредитную карту или банковский счет за границей.',
    marketingMessage2: 'легко. быстро. надежно. Для вас.',
    YAAD_SARIG_3: 'К сожалению, кредитная компания не одобрила сделку. Мы рекомендуем вам связаться с ней для решения проблемы, а затем повторить попытку. Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_4: 'К сожалению, ваша компания-эмитент кредитной карты не одобрила введенную вами транзакцию или данные карты.  Рекомендую попробовать еще раз и будем рады помочь с любыми вопросами.',
    YAAD_SARIG_6: 'Приносим извинения, но что-то не так с данными карты, которые вы ввели.  Мы рекомендуем вам попробовать еще раз.  Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_26: 'К сожалению, карта не принадлежит вашему идентификационному номеру. Вы можете попробовать еще раз с картой, которая принадлежит вам. Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_141: 'Сожалеем, но мы принимаем сейчас только карты Visa и MasterCard . Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_303: 'Приносим извинения, но ваша кредитная карта не поддерживает проверку безопасности кредитной компании, вы можете связаться с  компанией-эмитентом карты. Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_447: 'К сожалению, введенный вами номер карты неверен. Мы рекомендуем вам попробовать еще раз. Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_553: 'К сожалению, возникла проблема с кредитной компанией. Мы рекомендуем вам связаться с ней для решения проблемы, а затем повторить попытку. Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_999: 'К сожалению, что-то пошло не так, мы рекомендуем повторить попытку позже / Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_3000: 'К сожалению, кредитная компания не одобрила сделку. Мы рекомендуем вам связаться с ней для решения проблемы, а затем повторить попытку. Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_3003: 'Извините, похоже, что вы не одобрили платеж кредитной компании. Транзакция не была совершена.  Рекомендуем попробовать еще раз.  Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_9101: 'Приносим извинения, но ваша кредитная карта не поддерживает проверку безопасности кредитной компании, вы можете связаться с  компанией-эмитентом карты. Мы будем рады помочь с любыми вопросами.',
    YAAD_SARIG_GENERAL: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    INVALID_CARD: 'К сожалению,  карта на которую вы пытались вложить не поддерживается сервисом. Вы можете попробовать другую  карту. Мы будем рады помочь',
    AMOUNT_LIMITED: 'Извините, сумма, которую вы пытались перевести, превышает сумму карты получателя за рубежом, мы рекомендуем попробовать меньшую сумму и будем рады помочь с любыми вопросами.',
    CONTACT_GENERAL: 'К сожалению наш сайт закрыт на несколько минут, просьба подождать и попробовать зайти позже',
    BENEFICIARY_INVALID: 'Извините, имя получателя за границей не совпадает с данными карты за границей. Мы рекомендуем исправить, а затем отправить заново, мы будем рады помочь с любыми вопросами',
    CONTACT_DENIED: 'Мы сожалеем, но нам пришлось отклонить этот перевод, чтобы защитить вас, перевод не был завершен. Мы будем рады помочь.',
    CARD_DENIED: 'Извините, но вы не можете вложить на карту  этой страны. Мы будем рады помочь с любыми вопросами.',
    CARD_WRONG_DETAILS: 'К сожалению, данные о переводе за границу, которые вы вводите, неверны, мы рекомендуем исправить и повторить попытку. Мы будем рады помочь с любыми вопросами.',
    REGISTER_INFO_DETAILS_REQUIRED: 'Привет, нам нужны ваши данные и документ, удостоверяющий личность, чтобы отправить деньги за границу',
    REGISTER_INFO_SEARCH_ZIP: 'Привет, Вы можете найти свой почтовый индекс, нажав на увеличительное стекло',
    CARD_REJECTED: 'К сожалению, невозможен перевод получателю, которого вы выбрали, пожалуйста свяжитесь с нами для получения помощи',
    BLACKLIST: 'К сожалению, невозможен перевод получателю, которого вы выбрали, пожалуйста свяжитесь с нами для получения помощи',
    BLACKLIST_CARD: 'К сожалению, невозможен перевод получателю, которого вы выбрали, пожалуйста свяжитесь с нами для получения помощи',
    COMPANY_IDENTIFICATION: 'Извините, вы, кажется, пытаетесь зарегистрироваться с номером бизнес-клиента. Вы можете зарегистрироваться с вашим личным ID, и мы будем рады помочь с любыми вопросам',
    CUSTOMER_MONTHLY_LIMIT: 'Извините, вы достигли максимальной месячной суммы в GMT. Мы будем рады помочь с любыми вопросами.',
    CORRESPONDENT_EXCEPTION: 'К сожалению, мы получили ошибку при попытке отправить ваш перевод за границу.  Мы будем рады помочь. Служба поддержки клиентов',
    TEMPORARY_ERROR: 'К сожалению, мы получили ошибку при попытке отправить ваш перевод за границу.  Мы будем рады помочь. Служба поддержки клиентов',
    BAD_REQUEST: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    MAX_EXCEPTION: 'К сожалению, что-то пошло не так, мы рекомендуем попробовать позже. Мы будем рады ответить на любые вопросы.',
    SERVER_ERROR: 'Извините, в настоящее время система обновляется. Рекомендуем повторить попытку позже, будем рады ответить на любые вопросы.',
    CUSTOMER_DAILY_LIMIT_EXCEEDED: 'К сожалению, вы превысили максимальное количество переводов в день. По любому вопросу мы будем рады помочь в службе поддержки клиентов.',
    NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: 'Рады, что вы присоединились к нам! Вы превысили максимальное количество переводов, которое мы разрешаем новым клиентам в первую неделю. По любым вопросам, наша служба поддержки с радостью поможет.',
    NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: 'Мы рады, что Вы присоединились к нам! Вы превысили максимальную сумму для новых клиентов за первую неделю. По любым вопросам наша служба поддержки будет рада помочь.',
    NEW_BENEFICIARY_LIMIT_EXCEEDED: ' Извините, но вы можете сделать до 2 переводов на новую  карту в неделю. По любым вопросам, наша служба поддержки с радостью поможет.',
    NEW_BENEFICIARY_AMOUNT_EXCEEDED: 'Сожалеем, вы перевели максимальную сумму для пополнения  новой карты за рубежом. По любым вопросам наша служба поддержки будет рада помочь',
    INVALID_VERSION: 'Приносим извинения за неудобства, обновите страницу, чтобы увидеть последние обновления myGMT',
    BENEFICIARY_CARD_BLOCKED: 'Номер карты или банка на данный момент не поддерживается в сервисе, можно отправить на другую карту',
    ERROR_NEGATIVE: 'Сожалеем, невозможно выполнить транзакцию с отрицательной суммой, пожалуйста, измените сумму.',

    E_DECIMAL: 'только целые числа пожалуйста',
    E_INVALID_zipCode: 'Неверный индекс',
    E_LEMGTH: { 1: 'Только', 2: 'цифр' },
    E_MAX_VALUE: 'Превысили максимальную',
    E_MAX_CHAR: { 1: 'Максимум', 2: 'символов' },
    E_MIN_CHAR: { 1: 'Минимум', 2: 'символов' },
    E_MIN_passport: 'паспорт должен быть более 5 символов',
    E_NUMERIC: 'Только цифры',
    E_REGEX: 'Неверный формат поля',
    E_REGEX_email: 'Некорректный email',
    E_REGEX_english: 'Только английский',
    E_REGEX_mobileNumber: 'Пожалуйста, введите 10-ти значный Израильский номер телефона.',
    E_REGEX_israeliId: 'Неверный ID',
    E_REGEX_passport: 'Только буквы и цифры',
    E_REGEX_birthDateAge: 'Клиент от 18 до 85 лет',
    E_REGEX_zipCode: '7 цифр',
    E_REQUIRED: 'Обязательное поле',
    E_REQUIRED_amount: 'Обязательное поле',
    E_REQUIRED_currency: 'Обязательное поле',
    E_REQUIRED_destinationCountry: 'Обязательное поле',
    E_REQUIRED_agreement: 'Прочитайте и примите условия',

    STATUS_NOT_FOUND: 'Не найден',
    STATUS_CONFIRMED: 'Подтверждено',
    STATUS_READY_FOR_PAYMENT: 'Готов к оплате',
    STATUS_PAID: 'Выплачен',
    STATUS_SENT_TO_BANK: 'Отправлено в банк',
    STATUS_BEING_CANCEL: 'В процессе отмены',
    STATUS_READY_TO_CANCEL: 'Готов к отмене',
    STATUS_CANCELED: 'Отменено',
    STATUS_PREPARED: 'Готовый',
    STATUS_FAILED: 'Не успешный',
    STATUS_RETURNED: 'возвращенный',
    STATUS_ACCEPTED: 'Принято в обработку',
    STATUS_STAGED: 'Подготовленно',
    STATUS_INCOMPLETE: 'Неполный',
    STATUS_IN_PROCESS: 'Выплачивается',
    STATUS_SWIFT_TRANSMITTED: 'Передано',
    STATUS_SWIFT_APPROVED: 'Одобренный',
    STATUS_REJECTED: 'Отклоненный',
    STATUS_EXPIRED: 'Срок действия истек',
    STATUS_CLOSED: 'Закрыто',
    STATUS_SUBMITTED: 'Отправлено',

    DESC_CASHBACK: 'Пополнение в вашем CashBack-аккаунте',
    DESC_CASHBACK_WELCOME: 'Добро пожаловать! CashBack',
    DESC_CASHBACK_BIRTHDAY: 'С днём рождения! CashBack',
    DESC_CASHBACK_ONETIME: 'подарок CashBack‏‎',
    DESC_WALLET: 'Пополнение кошелька',
    DESC_MAX_TOPUP: 'Пополнение карты',
    DESC_MAX_PURCHASE: 'Приобретение и пополнение карты',
    DESC_MAX_ORDER: 'Заказ пополняемой карты',
    DESC_CONVERSION: 'Конвертация',
    DESC_019MOBILE: '019 mobile',
    DESC_REWIRE: 'Rewire',
    DESC_DEPOSIT_3MONTHS: 'Депозитный план на 3 месяца',
    DESC_DEPOSIT_6MONTHS: 'Депозитный план на 6 месяцeв',
    DESC_DEPOSIT_WITHDRAW: 'Снятие средств с депозита',
    DESC_DEPOSIT_INTEREST_PAYMENT: 'Выплата процентов по депозиту',

    DEPOSIT_IN: 'Вложение на депозитный счет',
    DEPOSIT_OUT: 'Снятие с депозитного счёта',
    DEPOSIT_INTEREST: 'Выплата процентов по депозиту',

    NAME_ALIPAY: 'ALIPAY',
    NAME_UNIONPAY: 'UNIONPAY',
    NAME_WECHATPAY: 'WECHATPAY',
    NAME_UPI: 'UPI',

    validation: {
        mock: '',
        beneficiary: {
            invalid: 'Неверное значение',
            maskedCardNumber: {
                invalidCountry: 'Карта не соответствует стране назначения',
                tranglo: 'Извените, вы внесли неверные данные получателя, пожалуйста внесите изменения и попробуйте снова'
            },
            accountIdentifier: {
                invalid: 'Invalid value',
                tranglo: 'Извените, вы внесли неверные данные получателя, пожалуйста внесите изменения и попробуйте снова'
            },
            walletIdentifier: {
                invalid: 'Invalid value',
                tranglo: 'Извените, вы внесли неверные данные получателя, пожалуйста внесите изменения и попробуйте снова'
            },
            phoneWallet: {
                unavailable: 'К сожалению, этот счет недоступен и не может получить средства. Пожалуйста, проверьте правильность счета, прежде чем повторить попытку.',
                mismatch: 'К сожалению, имя получателя и счет не совпадают. Вы можете исправить данные и повторить попытку.',
                invalidAccount: 'К сожалению аккаунт недействителен. Перед повторной попыткой проверьте данные счета.',
                barred: 'TPIN not registered. Please register TPIN under the same ID (NRC or Passport).'
            },
            phoneNumber: {
                format: {
                    passParam: 'Правильный формат: {format}'
                }
            },
        }
    },
}